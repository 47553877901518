import {actionTypes} from "./reducer";


export const vehiclesSetHandler = (vehicles, pageInfo, filterData, totalCount, isNextBatch=false) => ({
    type: actionTypes.VEHICLES_SET,
    payload: {
        vehicles: vehicles,
        pageInfo: pageInfo,
        filterData: filterData,
        totalCount: totalCount,
        isNextBatch: isNextBatch,
    }
});


export const currentScrollPositionSetHandler = (top, left) => ({
    type: actionTypes.CURRENT_SCROLL_POSITION_SET,
    payload: {
        top,
        left,
    }
});

export const nextBatchStartFetchingHandler = () => ({
    type: actionTypes.NEXT_BATCH_FETCHING
});


export const filterAddHandler = (key, value) => ({
    type: actionTypes.FILTER_ADD,
    payload: {
        key,
        value,
    }
});

export const filterDeleteHandler = (key, value) => ({
    type: actionTypes.FILTER_DELETE,
    payload: {
        key,
        value,
    }
});

export const filterReplaceHandler = (key, value) => ({
    type: actionTypes.FILTER_REPLACE,
    payload: {
        key,
        value,
    }
});

export const filterSetSalonDealsHandler = (values) => ({
    type: actionTypes.FILTER_SET_SALON_DEALS,
    payload: {
        values
    }
});

export const filterResetSalonDealsHandler = () => ({
    type: actionTypes.FILTER_RESET_SALON_DEALS
});

export const filterAddLabelsHandler = (labels) => ({
    type: actionTypes.FILTER_ADD_LABELS,
    payload: {
        labels
    }
});

export const filterRemoveLabelsHandler = (labels) => ({
    type: actionTypes.FILTER_REMOVE_LABELS,
    payload: {
        labels
    }
});

export const filtersSetHandler = (filters) => ({
    type: actionTypes.FILTERS_SET,
    payload: {
       filters
    }
});

export const filtersResetHandler = () => ({
    type: actionTypes.FILTERS_RESET
});

export const listTypeSetHandler = (listType) => ({
    type: actionTypes.SET_LIST_TYPE,
    payload: {
        listType
    }
});

