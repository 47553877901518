import React, {useContext} from 'react';
import OverlayWithSidebar from "../../../../common/OverlayWithSidebar";
import {useTranslation} from "react-i18next";
import {Scrollbars} from "react-custom-scrollbars-2";
import {filter, map} from "lodash";
import {QRCodeSVG} from "qrcode.react";
import {formatNumber, VEHICLE_PROMO_LIST_TYPES} from "../../../../utils";
import Advertisement from "./Advertisement";

const VehicleSideBar = (props) => {
    const {isOpen, close, vehicle, communicationShortUrl, listType, dealerRootUrl} = props;

    const {t} = useTranslation();

    const renderSidebar = () => {
        return (
            <div className="flex flex-col h-full">
                <div className="grid grid-cols-1 pt-10 pb-10 pr-16 pl-44">
                    <div
                        className="flex items-center justify-center justify-self-end w-14 h-14 rounded-full cursor-pointer bg-gray-lighter"
                        onClick={close}
                    >
                        <i className="fal fa-times text-gray-darker text-lg mt-0.5"/>
                    </div>
                </div>

                <div className="flex-1 max-h-full h-full overflow-y-scroll pt-0 pb-12 pl-30 pr-20 no-scrollbar">
                    <Scrollbars
                        autoHeight={true}
                        autoHeightMin={1080}
                        //autoHeightMax={1080}
                        //autoHide
                        //autoHideTimeout={1000}
                        //autoHideDuration={200}
                    >
                        <div className="pr-20">
                            <div className="text-3xl font-semibold mb-2">
                                {vehicle.make} {vehicle.model}
                            </div>
                            <div className="text-lg font-regular mb-8">
                                {vehicle.version}
                            </div>
                            <div className="font-semibold flex flex-row items-start mb-6">
                                <span className="text-base mt-0.5 mr-2">€</span>{" "}
                                <span className="text-4xl mr-2">
                      {formatNumber(vehicle.price)}
                    </span>{" "}
                                <span className="text-base mt-0.5">{t("BTWi")}</span>
                            </div>

                            <Advertisement vehicleLabels={vehicle?.labels} className="mb-6"/>
                            <div className="pb-12 border-b border-gray-light mb-12 flex flex-col">
                                <div className="flex flex-row mb-12">
                                    <div className="flex-grow text-lg font-semibold">
                                        {t("Specifications")}
                                    </div>
                                    {/*<i className="fa fa-chevron-up text-lg font-regular"></i>*/}
                                </div>
                                <div className="grid grid-cols-3 gap-6">
                                    <div className="bg-gray-lighter p-4">
                                        <div className="text-base font-regular">
                                            {t("Fuel")}
                                        </div>
                                        <div className="text-lg font-normal">
                                            {vehicle.fuel}
                                        </div>
                                    </div>
                                    <div className="bg-gray-lighter p-4">
                                        <div className="text-base font-regular">
                                            {t("1st registration")}
                                        </div>
                                        <div className="text-lg font-normal">
                                            {vehicle.firstRegistrationYear}
                                        </div>
                                    </div>

                                    <div className="bg-gray-lighter p-4">
                                        <div className="text-base font-regular">
                                            {t("Mileage")}
                                        </div>
                                        <div className="text-lg font-normal">
                                            {formatNumber(vehicle.mileage)} km
                                        </div>
                                    </div>
                                    <div className="bg-gray-lighter p-4">
                                        <div className="text-base font-regular">
                                            {t("Emissions")}
                                        </div>
                                        <div className="text-lg font-normal">
                                            {vehicle.co2} g/km
                                        </div>
                                    </div>
                                    <div className="bg-gray-lighter p-4">
                                        <div className="text-base font-regular">
                                            {t("KW / PK")}
                                        </div>
                                        <div className="text-lg font-normal">
                                            {vehicle.kw} {t('kW')} / {vehicle.hp} {t('PK')}
                                        </div>
                                    </div>

                                    {vehicle?.warranty && vehicle?.warranty !== 0 && <div className="badge p-4">
                                        <div className="text-base font-regular">
                                            {t("Warranty")}
                                        </div>
                                        <div className="text-lg font-normal">
                                            {vehicle?.warranty} {vehicle?.warranty === 1 ? t('year of warranty') : t('years of warranty')}
                                        </div>
                                    </div>}
                                </div>
                            </div>
                            {vehicle.equipmentGroups && (
                                <div className="flex flex-col">
                                    <div className="flex flex-row mb-12">
                                        <div className="flex-grow text-lg font-semibold">
                                            {t("Options and equipment")}
                                        </div>
                                        {/*<i className="fa fa-chevron-up text-lg font-regular"></i>*/}
                                    </div>
                                    <div className="flex flex-col gap-12">
                                        {map(filter(vehicle.equipmentGroups, equipmentGroup => equipmentGroup.key !== 'Pros'), (group) => {
                                            return (
                                                <div>
                                                    <div className="text-lg font-semibold mb-2">
                                                        {group.name}
                                                    </div>
                                                    <div>
                                                        {map(group.equipments, (eq) => {
                                                            return (
                                                                <div className="text-base font-regular mb-1">
                                                                    {eq.name}
                                                                </div>
                                                            );
                                                        })}
                                                    </div>
                                                </div>
                                            );
                                        })}
                                    </div>
                                </div>
                            )}
                        </div>
                    </Scrollbars>
                </div>

                <div className="mt-6 py-12 pl-30 pr-40 border-t border-gray-light">
                    <div className="text-xl font-semibold mb-7">
                        {t("See yourself behind the wheel? We too!")}
                    </div>

                    <div className="grid grid-cols-2 gap-4">
                        <div className="flex flex-col items-center border border-gray-100 p-2 rounded-lg">
                            <div className="mb-4">
                                <QRCodeSVG
                                    value={`${dealerRootUrl}${vehicle.url}&contact-form-open&campaign=SalonDeal`}
                                    fgColor={"#183C74"}
                                    bgColor={"transparent"}
                                    style={{height: "100%", width: "auto"}}
                                />
                            </div>
                            <div className="text-sm font-regular">
                                {t('Scan this QR code and contact me via')}{' '}
                                {listType === VEHICLE_PROMO_LIST_TYPES.AAPLUS ? 'audiapprovedplus.be' : 'myway.be'}
                                {' '}{t('for this vehicle')}.
                            </div>
                        </div>

                        <div className="flex flex-col items-center w-full border border-gray-100 p-2 rounded-lg">
                            <div className="mb-4">
                                <QRCodeSVG
                                    value={communicationShortUrl}
                                    fgColor={"#183C74"}
                                    bgColor={"transparent"}
                                    style={{height: "100%", width: "auto"}}
                                />
                            </div>
                            <div className="text-sm font-regular">
                                {t('Scan this QR code to stay informed about promotions and news at')}{' '}
                                {listType === VEHICLE_PROMO_LIST_TYPES.AAPLUS ? 'Audi Approved Plus' : 'My Way'}.
                            </div>
                        </div>
                    </div>

                </div>
            </div>
        );
    };

    return (
        <React.Fragment>
            <OverlayWithSidebar
                isOpen={isOpen}
                onClose={close}
                animation={true}
                sidebar={renderSidebar()}
                sidebarPosition="right"
                sidebarWidthClass="w-50vw"
                overlayClass="bg-gray-dark"
            />
        </React.Fragment>
    );
}

export default VehicleSideBar
