import { map, isString } from "lodash";
import {LANGUAGE_LABELS} from "./settings";

export const useAsset = (resource) => {
  return require(`/${resource}`).default;
};

export const toggleDisableBackgroundScroll = (disabled) => {
  //if (hasDocument()) {
  const html = document.getElementsByTagName("html")[0];
  const body = document.getElementsByTagName("body")[0];

  if (disabled) {
    if (html) html.classList.add("overflow-hidden");
    if (body) body.classList.add("overflow-y-scroll");
  }

  if (!disabled) {
    if (html && html.classList.contains("overflow-hidden")) {
      html.classList.remove("overflow-hidden");
    }
    if (body && body.classList.contains("overflow-y-scroll"))
      body.classList.remove("overflow-y-scroll");
  }
  //}
};

export const formatNumber = (number, minDigits = 0, maxDigits = 2) => {
  let nr = parseFloat(number);
  return nr.toLocaleString("nl-NL", {
    minimumFractionDigits: minDigits,
    maximumFractionDigits: maxDigits,
  });
};

export const formatPrice = (price, currency = "€") => {
  return `${currency}${formatNumber(price)}`;
};

export const map_vehicles = (vehicles) => {
  const mapped = map(vehicles, (vehicle) => {
    return {
      ...vehicle,
      images: JSON.parse(vehicle.images),
    };
  });
  return mapped;
};

export const isElementInViewport = (el) => {
  let r, html;
  if (!el || 1 !== el.nodeType) {
    return false;
  }
  html = document.documentElement;
  r = el.getBoundingClientRect();
  return !!r && r.bottom >= 0 && r.top <= html.clientHeight;
};


export const capitalizeFirstLetter = (string) => {
  if (!string) return '';

  if (isString(string)) return string[0].toUpperCase() + string.slice(1);

  return string;
};

export const resolveLanguageToSwitchCode = (currentLanguage) => {
  const possibleLanguages = Object.keys(LANGUAGE_LABELS);
  const index = possibleLanguages.indexOf(currentLanguage);
  possibleLanguages.splice(index, 1);
  return possibleLanguages[0]
}

export const animateCSS = (element, animation, prefix = 'animate__') =>
    // We create a Promise and return it
    new Promise((resolve, reject) => {
      const animationName = `${prefix}${animation}`;
      const node = document.querySelector(element);
      if(node){
        node.classList.add(`${prefix}animated`, animationName);

        // When the animation ends, we clean the classes and resolve the Promise
        function handleAnimationEnd(event) {
          event.stopPropagation();
          node.classList.remove(`${prefix}animated`, animationName);
          resolve('Animation ended');
        }

        node.addEventListener('animationend', handleAnimationEnd, {once: true});
      }else{
        reject('SOMETHING WRONG')
      }

    });

export const initialAnswer = {
  en: "I don't care",
  nl: "Ik geef er niet om",
  fr: "Cela m'est égal",
}

export const LANGUAGES = {
  "NL": 'nl',
  "FR": 'fr',
}

export const VEHICLE_PROMO_LIST_TYPES = {
  "AAPLUS": "AAPLUS",
  "MYWAY": "MYWAY",
}

export const LABEL_KEYS = {
  "AAPLUS": "audi_approved_plus",
  "MYWAY": "myway_(vw_skoda_audi_seat)",
  "WARRANTY_1Y": "action_1_year_extra_warranty",
  "WARRANTY_2Y": "action_2_year_extra_warranty",
  "WARRANTY_3Y": "action_3_year_extra_warranty",
  "DISCOUNT_500": "action_discount_500",
  "DISCOUNT_1000": "action_discount_1000",
  "DISCOUNT_1500": "action_discount_1500",
  "FUELCARD_250": "action_fuelcard_250",
  "FUELCARD_500": "action_fuelcard_500",
  "FUELCARD_750": "action_fuelcard_750",
  "PRICE_REDUCTION": "action_price_reduction",
}

export const GROUP_KEYS = {
  "WARRANTY": "WARRANTY",
  "DISCOUNT": "DISCOUNT",
  "FUEL_CARD": "FUEL_CARD",
  "PRICE_REDUCTION": "PRICE_REDUCTION",
}

export const LABEL_GROUPS = {
  [GROUP_KEYS.WARRANTY]: {key: GROUP_KEYS.WARRANTY, label: t => t('To 3 years of warranty')},
  [GROUP_KEYS.DISCOUNT]: {key: GROUP_KEYS.DISCOUNT, label: t => t('To 1500€ discount')},
  [GROUP_KEYS.FUEL_CARD]: {key: GROUP_KEYS.FUEL_CARD, label: t => t('Fuel card to 750€')},
  [GROUP_KEYS.PRICE_REDUCTION]: {key: GROUP_KEYS.PRICE_REDUCTION, label: t => t('Special offer')} ,
}

export const LABELS_FILTER = [
  {key: LABEL_KEYS.WARRANTY_1Y, label: (t) => t('1 year warranty'), group: LABEL_GROUPS.WARRANTY.key},
  {key: LABEL_KEYS.WARRANTY_2Y, label: (t) => t('2 year warranty'), group: LABEL_GROUPS.WARRANTY.key},
  {key: LABEL_KEYS.WARRANTY_3Y, label: (t) => t('3 year warranty'), group: LABEL_GROUPS.WARRANTY.key},
  {key: LABEL_KEYS.DISCOUNT_500, label: (t) => t('Discount 500'), group: LABEL_GROUPS.DISCOUNT.key},
  {key: LABEL_KEYS.DISCOUNT_1000, label: (t) => t('Discount 1000'), group: LABEL_GROUPS.DISCOUNT.key},
  {key: LABEL_KEYS.DISCOUNT_1500, label: (t) => t('Discount 1500'), group: LABEL_GROUPS.DISCOUNT.key},
  {key: LABEL_KEYS.FUELCARD_250, label: (t) => t('Fuel card 250'), group: LABEL_GROUPS.FUEL_CARD.key},
  {key: LABEL_KEYS.FUELCARD_500, label: (t) => t('Fuel card 500'), group: LABEL_GROUPS.FUEL_CARD.key},
  {key: LABEL_KEYS.FUELCARD_750, label: (t) => t('Fuel card 750'), group: LABEL_GROUPS.FUEL_CARD.key},
  {key: LABEL_KEYS.PRICE_REDUCTION, label: (t) => t('Price reduction'), group: LABEL_GROUPS.PRICE_REDUCTION.key}
]

export const LABEL_FILTER_KEYS = map(LABELS_FILTER, labelFilter => labelFilter.key)

export const IMAGE_SETS = {
  "AAPLUS": 'aap',
  "MYWAY": 'default',
}

export const getImageSet = (key) => {
  if(!key) return 'naked';

  if(IMAGE_SETS[key]) return IMAGE_SETS[key];

  return 'naked';
}

export const determineVehicleListType = (makeParam) => {
  return makeParam === 'audi' ? VEHICLE_PROMO_LIST_TYPES.AAPLUS : VEHICLE_PROMO_LIST_TYPES.MYWAY;
}

export const MARKETING_COMMUNICATION_URL_BY_LANGUAGE = {
  'nl': 'https://share-eu1.hsforms.com/1hcISdcC2Q-qxAVYRAMbO5Qf0x0l',
  'fr': 'https://share-eu1.hsforms.com/1MVY7IZUoRIeCRHzQpTdDPgf0x0l',
}