import React from "react";
import { formatNumber } from "../../../../utils";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";

const VehicleCard = (props) => {
    const {vehicle} = props;
    const {t} = useTranslation();

  let detailUrl = `/promo/detail/${vehicle.reference}`;
  const urlSearchParams = new URLSearchParams(window.location.search);
  const tmpParams = urlSearchParams.toString();
  detailUrl = tmpParams !== "" ? `${detailUrl}?${tmpParams}` : detailUrl;

  const hasWarranty = vehicle?.warranty && vehicle?.warranty !== 0;

  return (
    <Link to={detailUrl}>
      <div className="w-full relative mb-4" style={{ aspectRatio: "4/3" }}>
        <div
          className="w-full absolute top-0 left-0 object-cover object-center overflow-hidden"
          style={{ aspectRatio: "4/3", zIndex: 10 }}
        >
          <img
            src={vehicle?.images?.[0]?.url}
            alt="veihcle"
            style={{ width: 547 }}
          />
        </div>
        <div
          className="w-full bg-gray-lighter animate-pulse rounded-4 absolute top-0 left-0"
          style={{ aspectRatio: "4/3", zIndex: 0 }}
        />
      </div>
      <div className="font-semibold text-base mb-1 truncate">
        {vehicle.make} {vehicle.model}
      </div>
      <div className="font-regular text-xs text-gray-dark mb-3 truncate">
        {vehicle.version}
      </div>
      <div className="font-semibold text-xl flex flex-row items-start mb-3">
        <span className="text-xs mt-0.5 mr-1">€</span>{" "}
        <span className="mr-1">{formatNumber(vehicle.price)}</span>{" "}
        <span className="text-xs mt-0.5">{t("BTWi")}</span>
      </div>

      <div className="flex flex-row flex-nowrap mb-2">
        {vehicle.firstRegistrationYear && (
          <div className="bg-gray-lighter text-xs px-2 py-1 mr-1 rounded truncate">
            {vehicle.firstRegistrationYear}
          </div>
        )}
        {vehicle.mileage && (
          <div className="bg-gray-lighter text-xs px-2 py-1 mr-1 rounded truncate">
            {formatNumber(vehicle.mileage)} km
          </div>
        )}
        {vehicle.hp && vehicle.kw && (
          <div className="bg-gray-lighter text-xs px-2 py-1 rounded truncate">
            {vehicle.kw} {t('kW')} / {vehicle.hp} {t('PK')}
          </div>
        )}
      </div>
      <div className="flex flex-row flex-nowrap">
        {(vehicle.co2 || vehicle.co2 === 0) && (
          <div className="bg-gray-lighter text-xs px-2 py-1 rounded mr-1 truncate">
            {vehicle.co2} g/km CO2
          </div>
        )}
        {vehicle.fuel && (
          <div className="bg-gray-lighter text-xs px-2 py-1 mr-1 rounded truncate">
            {vehicle.fuel}
          </div>
        )}

        {hasWarranty && (
          <div className="text-xs px-2 py-1 badge truncate">
            {vehicle?.warranty}{" "}
            {vehicle?.warranty === 1
              ? t("year of warranty")
              : t("years of warranty")}
          </div>
        )}
      </div>
    </Link>
  );
};

export default VehicleCard;
