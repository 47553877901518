import React, { useContext, useEffect, useRef, useState } from "react";
import { map, times } from "lodash";
import { useTranslation } from "react-i18next";
import { Scrollbars } from "react-custom-scrollbars-2";
import { useParams } from "react-router-dom";
import { DataContext } from "../../../../index";
import VehicleSummary from "./VehicleSummary";
import { getImageSet } from "../../../../utils";

const VEHICLE_QUERY = `query VehicleSearch($reference: String, $settings: VehicleSearchSettings) {
    vehicleSearch(reference: $reference, settings: $settings) {
        edges {
            node {
                url
                reference
                make
                model
                version
                price
                fuel
                firstRegistrationYear
                firstRegistrationMonth
                gearbox
                warranty
                kw
                hp
                mileage
                co2
                dealer {
                    legalName
                    name
                    address
                    postal
                    city
                    tel
                    contactUrl
                }
                
                labels {
                  key
                  name
                }
      
                salonDeal {
                    key
                    name
                }

                equipmentGroups {
                    name
                    key
                    equipments {
                        key
                        name
                        priority
                    }
                }

                videoUrl
                images {
                    key
                    url
                    priority
                }
                images360SpinnerUrl
            }
        }
    }
}`;

const VehicleDetail = (props) => {
  const { reference } = useParams();
  const { state, dispatch } = useContext(DataContext);
  const [vehicle, setVehicle] = useState(undefined);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(undefined);
  const isInitialMount = useRef(true);

  const [videoFullScreen, setVideoFullScreen] = useState(false);

  const { t } = useTranslation();

  useEffect(() => {
    // ugly work around, but it works ...
    if (isInitialMount.current) {
      isInitialMount.current = false;
    } else {
      fetchVehicle(reference);
    }
  }, [state.language, state.promo.filters]);

  const fetchVehicle = (reference) => {
    setLoading(true);
    fetch("/graphql", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        "Accept-Language": state.language,
      },
      body: JSON.stringify({
        query: VEHICLE_QUERY,
        variables: {
          reference: reference,
          settings: {
            imageSetKey: getImageSet(state?.promo?.listType),
          },
        },
      }),
    })
      .then((res) => res.json())
      .then((res) => {
        const result = res.data.vehicleSearch.edges;
        if (result.length === 1) {
          setVehicle(result[0].node);
        }
        setLoading(false);
      })
      .catch((error) => {
        setError("ERROR");
        setLoading(false);
      });
  };

  useEffect(() => {
    fetchVehicle(reference);
  }, [reference]);

  const handleVideoFullScreen = () => {
    setVideoFullScreen(!videoFullScreen);
  };

  useEffect(() => {
    const videoEl = document.getElementById("vehicleVideo");
    if (vehicle && videoEl) {
      videoEl.currentTime = 19;
    }
  }, [vehicle]);

  if (loading) {
    return (
      <React.Fragment>
        <div
          className="grid grid-rows-2 grid-flow-col"
          style={{ gap: "1.6vw" }}
        >
          {times(6, () => {
            return (
              <div
                className="bg-gray-lighter animate-pulse rounded-4"
                style={{ minWidth: "29.218vw", minHeight: "40vh" }}
              ></div>
            );
          })}
        </div>
      </React.Fragment>
    );
  }

  return (
    <React.Fragment>
      <Scrollbars
        //ref={scrollRef}
        autoHeight={true}
        autoHeightMax={1080}
        autoHide
        autoHideTimeout={1000}
        autoHideDuration={200}
      >
        <React.Fragment>
          {!vehicle && <div>{t("Vehicle not found...")}</div>}

          {vehicle && (
            <React.Fragment>
              <div
                className="grid grid-rows-2 grid-flow-col"
                style={{ gap: "1.6vw" }}
              >
                {vehicle?.videoUrl && (
                  <div
                    className={`transition-all ${
                      videoFullScreen
                        ? "fixed top-0 left-0 z-50 w-full h-screen flex items-center justify-center"
                        : ""
                    }`}
                    style={{
                      minWidth: "29.218vw",
                      background: videoFullScreen ? "rgba(0,0,0,.7)" : "none",
                    }}
                    onClick={(e) => {
                      e.stopPropagation();
                      handleVideoFullScreen();
                    }}
                  >
                    <div
                      className={`relative transition-all ${
                        videoFullScreen ? "" : "w-full mb-4"
                      }`}
                      style={{
                        aspectRatio: videoFullScreen ? "auto" : "4/3",
                        width: videoFullScreen ? "1024px" : "100%",
                        height: videoFullScreen ? "768px" : "100%",
                      }}
                    >
                      <div
                        id="videoBox"
                        className="w-full absolute top-0 left-0 object-cover object-center overflow-hidden"
                        style={{ aspectRatio: "4/3", zIndex: 10 }}
                      >
                        <video
                          id="vehicleVideo"
                          width="100%"
                          muted="true"
                          autoplay="true"
                          loop="true"
                          webkitplaysinline="true"
                          playsinline="true"
                          style={{ width: "100%" }}
                        >
                          <source src={vehicle.videoUrl} type="video/mp4" />
                        </video>
                      </div>
                      <div
                        className={`w-full bg-gray-lighter animate-pulse rounded-4 absolute top-0 left-0 flex items-center justify-center ${
                          videoFullScreen ? "hidden" : ""
                        }`}
                        style={{ aspectRatio: "4/3", zIndex: 0 }}
                      >
                        <div class="lds-ellipsis">
                          <div></div>
                          <div></div>
                          <div></div>
                          <div></div>
                        </div>
                      </div>
                    </div>
                  </div>
                )}

                {map(vehicle.images, (image) => {
                  return (
                    <div className="" style={{ minWidth: "29.218vw" }}>
                      <div
                        className="w-full relative mb-4"
                        style={{ aspectRatio: "4/3" }}
                      >
                        <div
                          className="w-full absolute top-0 left-0 object-cover object-center overflow-hidden"
                          style={{ aspectRatio: "4/3", zIndex: 10 }}
                        >
                          <img src={image.url} />
                        </div>
                        <div
                          className="w-full bg-gray-lighter animate-pulse rounded-4 absolute top-0 left-0"
                          style={{ aspectRatio: "4/3", zIndex: 0 }}
                        ></div>
                      </div>
                    </div>
                  );
                })}
                {/* Added 2 extra to make last images visible*/}
                <div className="" style={{ minWidth: "29.218vw" }}></div>
                <div className="" style={{ minWidth: "29.218vw" }}></div>
              </div>
            </React.Fragment>
          )}
        </React.Fragment>
      </Scrollbars>
      {vehicle && <VehicleSummary vehicle={vehicle} />}
    </React.Fragment>
  );
};

export default VehicleDetail;
