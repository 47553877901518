import React, {useContext, useState} from "react";

import {DataContext} from "../../../index";
import {languageSetHandler} from "../../../state/actions";
import {LANGUAGE_LABELS} from "../../../settings";

export default function LanguagePicker() {
  const {state, dispatch} = useContext(DataContext);
  const [active, setActive] = useState(false);

  //couple of lines to get current language as last in array
  const possibleLanguages = Object.keys(LANGUAGE_LABELS);
  const index = possibleLanguages.indexOf(state.language);
  possibleLanguages.splice(index, 1);
  possibleLanguages.push(state.language);

  return (
    <React.Fragment>
      <div class="space-x-1 text-center flex flex-row">
        {active ? (
          possibleLanguages.map((language, index) => {
            return (
              <div
                key={'lang-' + index}
                onClick={() => {
                  setActive(false);
                  dispatch(languageSetHandler(language))


                }}
                class="btn btn-pill text-xs font-normal"
              >
                {LANGUAGE_LABELS[language]}
              </div>
            );
          })
        ) : (
          <div
            onClick={() => {
              setActive((prevValue) => !prevValue);
            }}
            class="btn btn-pill text-xs font-normal"
          >
            {LANGUAGE_LABELS[state.language]}
          </div>
        )}
      </div>
    </React.Fragment>
  );
}
