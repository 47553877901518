
//*************************
//********** GENERAL ********
//*************************

export const DEFAULT_LANGUAGE = "nl"
export const AVAILABLE_LANGUAGES = ["nl", "fr"]

export const LANGUAGE_LABELS = {
    fr: "Français",
    nl: "Nederlands",
};

export const DEVELOPMENT_MODE = false

//*************************
//********** PROMO ********
//*************************
export const PROMO_BATCH_SIZE = 35;
export const PROMO_SECONDS_OF_IDLENESS = 180 // 3 minutes ATM
export const PROMO_SECONDS_OF_IDLENESS_FOR_HAND = 10

//*************************
//********** WALL *********
//*************************
export const NUMBER_OF_CARS_IN_VIEW = 8 //number of cars we want to show on the screen
export const X_TH_CAR_SELECTED = 3
export const X_TH_CAR_SELECTED_INDEX = X_TH_CAR_SELECTED-1
export const seconds_of_idleness_for_hand = 10
export const seconds_of_idleness_for_scroll = 70;
export const seconds_between_cars_for_scroll = 5;

export const greenGradient = {left: "#21B686", right: "#3FFABE"}
export const lightBlueGradient = { left: "#20A1DB", right: "#6CDFFF" };
export const darkBlueGradient = { left: "#183C74", right: "#0C8DC7" };

export const backgroundGradients = [lightBlueGradient, greenGradient, darkBlueGradient]

export const colorSchemes = [
    {
        qrColor: "white",
        qrBlockBgColor: "#183C74",
        qrTextColor: "#58CBFE",
        gradient: lightBlueGradient,
    },
    {
        qrColor: "white",
        qrBlockBgColor: "#054530",
        qrTextColor: "#96FCDB",
        gradient: greenGradient,
    },
    {
        qrColor: "#183C74",
        qrBlockBgColor: "#62CBFE",
        qrTextColor: "white",
        gradient: darkBlueGradient,
    },
];


//*************************
//******** CHOOSE ********
//*************************
