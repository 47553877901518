import React, {useContext, useState, useEffect} from "react";
import VehicleList from "../components/promo/vehicle/list/VehicleList";
import LanguagePicker from "../components/promo/general/LanguagePicker";
import swipeHorizontal from "../assets/swipe-horizontal.svg";
import FiltersContainer from "../components/promo/vehicle/filters/FiltersContainer";
import IdleWrapper from "../common/IdleWrapper";
import {PROMO_SECONDS_OF_IDLENESS_FOR_HAND} from "../settings";
import { useNavigate } from "react-router-dom";
import {resetALlHandler} from "../state/actions";
import {DataContext} from "../index";
import { useIdleTimer } from "react-idle-timer";
import {useTranslation} from "react-i18next";
import Switch from "../common/Switch";
import {filter, includes, some} from "lodash";
import {filterResetSalonDealsHandler, filterSetSalonDealsHandler} from "../components/promo/actions";
import mywayLogo from "../assets/myway-logo.webp";
import aaplusLogo from "../assets/aaplus-logo.png";
import {LABEL_FILTER_KEYS, VEHICLE_PROMO_LIST_TYPES} from "../utils";
import {useVehicleListType} from "../hooks/useVehicleListType";

const PromoList = (props) => {
    const {state, dispatch} = useContext(DataContext);
    const [loading, setLoading] = useState(false);
    const {addTheme} = useVehicleListType();
    const navigate = useNavigate();
    const {t} = useTranslation();

    const [isIdle, setIsIdle] = useState(false);
    const [showScrollIcon, setShowScrollIcon] = useState(false);
    const filters = state?.promo?.filters || []

    const urlSearchParams = new URLSearchParams(window.location.search);
    const makeParam = urlSearchParams.get('make')?.toLowerCase();

    console.log('PROMO STATE: ', state)

    // Effect to determinate if the promo list is audi or myway
    useEffect(() => {
        addTheme(makeParam)
    }, [makeParam]);


    //code for idleness
    const onIdle = () => {
        setIsIdle(true);
        setShowScrollIcon(true);
    };

    const onActive = (event) => {
        idleTimer.reset();
        setIsIdle(false);
        scrollIconFadeOut();
        setTimeout(() => {setShowScrollIcon(false)}, 500);
    };

    const onAction = () => {
        setIsIdle(false);
        scrollIconFadeOut();
        setTimeout(() => {setShowScrollIcon(false)}, 500);
    }

    const idleTimer = useIdleTimer({
        onIdle,
        onActive,
        onAction,
        timeout: 1000 * PROMO_SECONDS_OF_IDLENESS_FOR_HAND
    });

    const scrollIconFadeOut = () => {
        const el = document.getElementById('scrollIconBox');
        if(el){
            el.classList.remove('animate__fadeInUp');
            el.classList.add('animate__fadeOutDown');
        }
    }

    useEffect(() => {
        setShowScrollIcon(true)
    }, [])
    //end code for idleness

    // const salonDealsFilterActive = !!find(filters, (f) => f.key === "labels" && f.value === LABEL_KEYS.SALONDEAL)
    const salonDealsFilterActive = some(filter(filters, (f) => f.key === "labels"), labelFilter => includes(LABEL_FILTER_KEYS, labelFilter.value))


    const renderTitle = () => {
        const title = salonDealsFilterActive ? t('Discover here all our salon deals vehicles.') : t('Discover here all our available stock.');
        let logo = null
        if (state?.promo?.listType === VEHICLE_PROMO_LIST_TYPES.AAPLUS) logo = aaplusLogo;
        if (state?.promo?.listType === VEHICLE_PROMO_LIST_TYPES.MYWAY) logo = mywayLogo;

        return <div className="flex items-center">
            {logo && <img src={logo} alt={title} className="mr-8 max-w-40 w-40"/>}
            <div className="font-semibold text-4xl">
                {title}
            </div>
        </div>
    }

    const switchOptions = {
        "ALL": {value: null, label: t('Full stock')},
        "SALONDEAL": {value: "salondeal", label: t('Salon deals')}
    }

    return (
        <IdleWrapper handleOnIdle={() => {
            const urlSearchParams = new URLSearchParams(window.location.search);
            const makeParam = urlSearchParams.get('make')?.toLowerCase();
            navigate({
                pathname: '/promo/list',
                search: urlSearchParams.toString(),
            });
            dispatch(resetALlHandler())
            // addTheme(makeParam)
        }}>
            <div className="h-screen overflow-hidden">
                <div className="grid grid-cols-6 items-center h-12.592vh px-3.125vw">
                    <>
                        <div className="justify-self-start">
                            <LanguagePicker/>
                        </div>
                        <div className="flex items-center justify-between text-center col-span-4 gap-4">
                            {renderTitle()}

                            <Switch activeOption={salonDealsFilterActive ? switchOptions['SALONDEAL'].value : null}
                                    options={
                                        [
                                            {value: switchOptions['ALL'].value, label: switchOptions['ALL'].label},
                                            {value: switchOptions['SALONDEAL'].value, label: switchOptions['SALONDEAL'].label},
                                        ]
                                    }
                                    onChange={selectedValue => {
                                        if(selectedValue === switchOptions['SALONDEAL'].value && !salonDealsFilterActive){
                                            // ad salon deals labels
                                            dispatch(filterSetSalonDealsHandler(LABEL_FILTER_KEYS))
                                        } else{
                                            // Remove salon deals labels
                                            if(salonDealsFilterActive) {
                                                dispatch(filterResetSalonDealsHandler())
                                            }
                                        }
                                    }}
                            />
                        </div>

                        <FiltersContainer/>
                    </>

                </div>
                <div className="pl-3.125vw pb-3.125vw overflow-x-auto overflow-y-hidden"
                     style={{height: "87.408vh"}}
                >
                    <VehicleList {...props} loading={loading} setLoading={setLoading}/>
                    {showScrollIcon && <div
                        id='scrollIconBox'
                        className='fixed bottom-1 left-[50vw] animate__animated animate__fadeInUp animate__faster'
                        style={{marginLeft: '-2.5rem'}}
                    >
                        <img
                            className="animate__animated animate__headShake animate__delay-1s"
                            src={swipeHorizontal}
                            alt="swipe list"
                            style={{height: "5rem", width: "auto"}}
                        />
                    </div>}
                </div>
            </div>
        </IdleWrapper>
    );
};

export default PromoList;
