import React, {useContext, useState} from "react";
import {DataContext} from "../../../index";
import {languageSetHandler} from "../../../state/actions";
import {LANGUAGE_LABELS} from "../../../settings";
import {resolveLanguageToSwitchCode} from "../../../utils";

export default function LanguagePicker() {
  const {state, dispatch} = useContext(DataContext);

  const languageToSwitchTo = resolveLanguageToSwitchCode(state.language)

  return (
    <React.Fragment>
      <div className="space-x-1 text-center flex flex-row">
          <div
            onClick={() => {
              dispatch(languageSetHandler(languageToSwitchTo))
            }}
            className="btn btn-pill text-base font-normal w-40 "
          >
            {LANGUAGE_LABELS[languageToSwitchTo]}
          </div>
      </div>
    </React.Fragment>
  );
}
