import React, {useContext} from "react";
import {DataContext} from "../../../../index";
import {useTranslation} from "react-i18next";
import {every, filter, find, head, last, map, reverse, some, sortBy, split} from 'lodash';
import {filterAddHandler, filterAddLabelsHandler, filterDeleteHandler, filterRemoveLabelsHandler, filterReplaceHandler, filtersSetHandler} from "../../actions";
import {hasFilter} from "./utils";
import {formatPrice, LABEL_GROUPS, LABELS_FILTER, VEHICLE_PROMO_LIST_TYPES} from "../../../../utils";
import CheckboxWithText from '../../../../common/CheckboxWithText';
import RadioWithText from '../../../../common/RadioWithText';

const Filters = () => {
    const {state, dispatch} = useContext(DataContext);
    const {makes, fuels, priceRanges, labels} = state.promo.filterData
    const {filters} = state.promo
    const {t} = useTranslation();

    const isAaplusList = state.promo.listType === VEHICLE_PROMO_LIST_TYPES.AAPLUS
    const filteredMakes = filter(makes, make => make.key !== 'audi')
    const audiModels = find(makes, make => make.key === 'audi')?.models || []

    const mostSearchedMakes = reverse(sortBy(filter(filteredMakes, (f) => f.priority > 0), ['priority']));
    const otherMakes = filter(filteredMakes, (f) => f.priority === 0);

    console.log('Current filters: ', filters)

    const handeBudgetRangeClick = (key, value) => {
        const currentFilter = find(filters, (f) => f.key === key)
        if (!currentFilter) {
            dispatch(filterAddHandler(key, value))
        } else {
            if (currentFilter.value[0] === value[0] && currentFilter.value[1] === value[1]) {
                dispatch(filterDeleteHandler(key, value))
            } else {
                dispatch(filterReplaceHandler(key, value))
            }

        }
    }

    const handleFilterClick = (key, value) => {
        if (!hasFilter(filters, key, value)) {
            dispatch(filterAddHandler(key, value))
        } else {
            dispatch(filterDeleteHandler(key, value))
        }
    }

    const handleMakeModelFilter = (makeKey, modelKey) => {
        const existingFilters = filters.filter((f) => f.key === 'makeModel');

        if (modelKey) {
            // Handle model selection
            const existingModelFilter = existingFilters.find(
                (f) => f.value === `${makeKey}|${modelKey}`
            );

            if (existingModelFilter) {
                // If the model already exists, remove it
                dispatch(filterDeleteHandler('makeModel', existingModelFilter.value));

                // If no models are left, add the make back
                const remainingModels = existingFilters.filter(
                    (f) => head(split(f.value, '|')) === makeKey && f.value !== existingModelFilter.value
                );
                if (remainingModels.length === 0) {
                    dispatch(filterAddHandler('makeModel', makeKey));
                }
            } else {
                // Remove make-only filter if it exists
                const makeOnlyFilter = existingFilters.find(
                    (f) => f.value === makeKey
                );
                if (makeOnlyFilter) {
                    dispatch(filterDeleteHandler('makeModel', makeOnlyFilter.value));
                }

                // Add the new model to the filters
                dispatch(filterAddHandler('makeModel', `${makeKey}|${modelKey}`));
            }
        } else {
            // Handle make selection
            const existingMakeFilters = existingFilters.filter(
                (f) => head(split(f.value, '|')) === makeKey
            );

            if (existingMakeFilters.length > 0) {
                // Remove all models and make for this make
                existingMakeFilters.forEach((f) =>
                    dispatch(filterDeleteHandler('makeModel', f.value))
                );
            } else {
                // Add make-only filter
                dispatch(filterAddHandler('makeModel', makeKey));
            }
        }
    };

    const handleApplyFilters = () => {
        dispatch(filtersSetHandler(filters))
    }

    const formatBudgetRangeLabel = (range) => {
        if (range.min === 0) {
            return t('Less than') + ' ' + formatPrice(range.max)
        } else if (range.max === 0) {
            return t('More than') + ' ' + formatPrice(range.min)
        } else {
            return t('Between') + ' ' + formatPrice(range.min) + ' ' + t('and') + ' ' + formatPrice(range.max)
        }
    }

    const labelGroupKeys = map(Object.keys(LABEL_GROUPS), (groupKey) => groupKey)

    const isMakeSelected = (makeKey) => {
        return  some(filters, (f) => f.key === "makeModel" && head(split(f.value, '|')) === makeKey)
    }

    const modelsByMake = (makeKey) => {
        return find(makes, makeModel => makeModel.key === makeKey)?.models || []
    }

    const isModelSelected = modelKey => {
        return some(filters, (f) => f.key === "makeModel" && last(split(f.value, '|')) === modelKey)
    }

    return (
        <div className="pr-20">
            <div className="mb-12 text-lg font-normal">{t('Salon Deals')}</div>
            <div className='pb-6 mb-12 border-b border-gray-light'>
                {map(labelGroupKeys, labelGroupKey => {
                    const labelsByGroupKey = filter(LABELS_FILTER, labelFilter => labelFilter.group === labelGroupKey);
                    const labelKeys = map(labelsByGroupKey, labelByGroupKey => labelByGroupKey.key)
                    const translatedLabelGroup = LABEL_GROUPS?.[labelGroupKey]?.label(t) || '';
                    const isChecked = every(labelKeys, labelKey => find(filters, (f) => f.key === "labels" && f.value === labelKey))

                    return <CheckboxWithText key={labelGroupKey}
                                             label={translatedLabelGroup}
                                             className="mb-6"
                                             checked={isChecked}
                                             onClick={() => isChecked ? dispatch(filterRemoveLabelsHandler(labelKeys)) : dispatch(filterAddLabelsHandler(labelKeys))}
                    />
                })}
            </div>


            <div className="mb-12 text-lg font-normal">{t('Price ranges')}</div>

            <div className='pb-6 mb-12 border-b border-gray-light'>
                {map(priceRanges, (range, index) => {
                    return (
                        <RadioWithText
                            key={`range-${index}`}
                            classNames="mb-6"
                            label={formatBudgetRangeLabel(range)}
                            checked={find(filters, (f) => f.key === "price" && f.value[0] === range.min && f.value[1] === range.max)}
                            enabled={range.available}
                            onClick={() => handeBudgetRangeClick('price', [range.min, range.max])}
                        />
                    )
                })}
            </div>

            <div className="mb-12 text-lg font-normal">{t('Fuel')}</div>
            <div className='pb-6 mb-12 border-b border-gray-light'>
                {map(fuels, (fuel) => {
                    const selected = find(filters, (f) => f.key === "fuel" && f.value === fuel.key)
                    return (
                        <CheckboxWithText key={fuel.key}
                                          label={fuel.description ? fuel.description : fuel.key}
                                          className={`mb-6 ${(!fuel.available) ? 'opacity-50' : ''}`}
                                          checked={selected}
                                          onClick={() => fuel.available ? handleFilterClick('fuel', fuel.key) : undefined}
                        />

                    )
                })}
            </div>


            {/*Only Myway if there are makes (without audi)*/}
            {!isAaplusList && (mostSearchedMakes.length > 0 || otherMakes.length > 0) &&
                <React.Fragment>
                    <div className="mb-10 text-lg font-normal">{t('Make')}</div>
                    <div className="mb-5 text-lg text-gray-dark font-regular uppercase">{t('Most searched makes')}</div>
                    <div className='pb-10'>
                        {map(mostSearchedMakes, (make) => {
                            const makeSelected = isMakeSelected(make.key)
                            const models = modelsByMake(make.key)
                            return (
                                <div>
                                    <CheckboxWithText key={make.key}
                                                      label={make.description}
                                                      className={`mb-6 ${(!make.available) ? 'opacity-50' : ''}`}
                                                      checked={makeSelected}
                                                      onClick={() => make.available ? handleMakeModelFilter(make.key) : undefined}
                                    />
                                    {makeSelected && <div className="ml-6">
                                        {map(models, model => {
                                            const modelSelected = isModelSelected(model.key)
                                            return <CheckboxWithText key={model.key}
                                                                     label={model.description}
                                                                     className={`mb-6`}
                                                                     checked={modelSelected}
                                                                     onClick={() => handleMakeModelFilter(make.key, model.key)}
                                            />
                                        })}
                                    </div>}

                                </div>
                            )
                        })}
                    </div>
                    <div className="mb-5 text-lg text-gray-dark font-regular uppercase">{t('Other makes')}</div>
                    <div className='pb-10'>{map(otherMakes, (otherMake) => {
                        const otherMakeSelected = isMakeSelected(otherMake.key)
                        const otherMakeModels = modelsByMake(otherMake.key)
                        return (
                            <div>
                                <CheckboxWithText key={`other-make-${otherMake.key}`}
                                                  label={otherMake.description}
                                                  className={`mb-6 ${(!otherMake.available) ? 'opacity-50' : ''}`}
                                                  checked={otherMakeSelected}
                                                  onClick={() => otherMake.available ? handleMakeModelFilter(otherMake.key) : undefined}
                                />
                                {otherMakeSelected && <div className="ml-6">
                                    {map(otherMakeModels, otherMakeModel => {
                                        const otherMakesModelSelected = isModelSelected(otherMakeModel.key)
                                        return <CheckboxWithText key={otherMakeModel.key}
                                                                 label={otherMakeModel.description}
                                                                 className={`mb-6`}
                                                                 checked={otherMakesModelSelected}
                                                                 onClick={() => handleMakeModelFilter(otherMake.key, otherMakeModel.key)}
                                        />
                                    })}
                                </div>}
                            </div>
                        )
                    })}</div>
                </React.Fragment>
            }

            {/*Only Aaaplus, and only models*/}
            {isAaplusList && audiModels?.length > 0 && <React.Fragment>
                <div className="mb-10 text-lg font-normal">{t('Models')}</div>
                <div className='pb-10'>
                    {map(audiModels, (model) => {
                        const makeKey = 'audi'
                        const aaplusModelSelected = isModelSelected(model.key)
                        return (
                            <CheckboxWithText key={model.key}
                                              label={model.description}
                                              className={`mb-6`}
                                              checked={aaplusModelSelected}
                                              onClick={() => handleFilterClick('makeModel', `${makeKey}|${model.key}`)}
                            />
                        )
                    })}
                </div>
            </React.Fragment>}
        </div>
    );
};

export default Filters;
