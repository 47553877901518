import React, {useContext, useEffect} from 'react';
import VehicleDetail from "../components/promo/vehicle/detail/VehicleDetail";
import logoSalonDeals from "../assets/MyWay-SalonDeals-2023.png";
import mywayLogoNl from "../assets/myway-logo-nl.webp";
import mywayLogoFr from "../assets/myway-logo-fr.webp";
import aaplusLogo from "../assets/aaplus-logo.png";
import {Link} from "react-router-dom";
import IdleWrapper from "../common/IdleWrapper";
import {DEVELOPMENT_MODE} from "../settings";
import { useNavigate } from "react-router-dom";
import {resetALlHandler} from "../state/actions";
import {DataContext} from "../index";
import {useVehicleListType} from "../hooks/useVehicleListType";
import {LANGUAGES, VEHICLE_PROMO_LIST_TYPES} from "../utils";
import {useTranslation} from "react-i18next";

const PromoDetail = (props) => {
    const {state, dispatch} = useContext(DataContext);
    const navigate = useNavigate();
    const {addTheme} = useVehicleListType();
    const {t} = useTranslation();

    let backUrl = `/promo/list`
    const urlSearchParams = new URLSearchParams(window.location.search);
    const tmpParams = urlSearchParams.toString()
    backUrl = (tmpParams !== "") ? `${backUrl}?${tmpParams}` : backUrl

    const makeParam = urlSearchParams.get('make')?.toLowerCase();

    // Effect to determinate if the promo list is audi or myway
    useEffect(() => {
        addTheme(makeParam)
    }, [makeParam]);

    const renderTitle = () => {
        if (state?.promo?.listType === VEHICLE_PROMO_LIST_TYPES.MYWAY) {
            const logo = state?.language === LANGUAGES.FR ? mywayLogoFr : mywayLogoNl;
            return <img src={logo}
                        alt="My Way"
                        className="cursor-hand"
                        style={{width: 600, maxWidth: 600}}
                        onClick={() => {
                            if (DEVELOPMENT_MODE) {
                                navigate('/')
                            }
                        }}
            />
        }

        if (state?.promo?.listType === VEHICLE_PROMO_LIST_TYPES.AAPLUS) {
            const title = t('A premium second-hand car with extensive warranties.');
            return <div className="flex items-center">
                <img src={aaplusLogo}
                     alt="Audi approved plus"
                     className="mr-8 max-w-40 w-40 cursor-hand"
                     onClick={() => {
                         if (DEVELOPMENT_MODE) {
                             navigate('/')
                         }
                     }}
                />
                <div className="font-semibold text-4xl">
                    {title}
                </div>
            </div>
        }

        return <img
            className="cursor-hand"
            src={logoSalonDeals}
            alt="Salon Deals - MyWay - 2024"
            onClick={() => {
                if (DEVELOPMENT_MODE) {
                    navigate('/')
                }
            }}
        />

    }

    return (
        <IdleWrapper handleOnIdle={() => {
            const urlSearchParams = new URLSearchParams(window.location.search);
            const makeParam = urlSearchParams.get('make')?.toLowerCase();
            navigate({
                pathname: '/promo/list',
                search: urlSearchParams.toString(),
            });
            dispatch(resetALlHandler())
            // addTheme(makeParam)
        }}>
            <div className="h-screen overflow-hidden">
                <div className="flex items-center h-12.592vh px-3.125vw">
                    <div className="mr-20">
                        <Link to={backUrl}>
                            <div className='flex items-center justify-center justify-self-end w-14 h-14 rounded-full cursor-pointer bg-gray-lighter'>
                                <i className='fal fa-arrow-left text-gray-darker text-lg mt-0.5'/>
                            </div>
                        </Link>
                    </div>
                    <div className="flex flex-1 justify-center">
                        {renderTitle()}
                    </div>

                </div>

                <div className="pl-3.125vw pb-3.125vw h-full overflow-x-auto overflow-y-hidden">
                    <VehicleDetail {...props} />
                </div>
            </div>
        </IdleWrapper>
    );
};

export default PromoDetail;
